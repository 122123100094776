import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="wrapper">
          <nav>
            <a href="/jak-nas-kontaktovat/#" className="logo2" />
            <a href="/pravni-sdeleni/">Právní sdělení</a>
            <a href="/politika-ochrany-osobnich-udaj/">
              Politika ochrany osobních údajů
            </a>
            <a href="/zasady-pouzivani-souboru-cookies/">
              Zásady používání souborů cookies
            </a>
            <a href="/jak-nas-kontaktovat/">Jak nás kontaktovat</a>
            
          </nav>
          <a id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Nastavení</a>
          <p>
            © JNTL Consumer Health (Czech Republic) s.r.o. 2023. Tyto webové stránky vydává
            společnost JNTL Consumer Health (Czech Republic) s.r.o., Walterovo náměstí 329/1,
            Praha 5, 158 00, Česká republika, která je výhradně zodpovědná za
            jejich obsah. Tyto webové stránky se nejlépe zobrazují v
            prohlížečích: Internet Explorer 7 +, Mozila Firefox 22+, Google
            Chrome 28+, Opera 15+ &nbsp;a Safari 5.1.6.
          </p>
          <p>&nbsp;Stránky jsou určené pro adresáty z České republiky.</p>
          <p>Poslední aktualizace dne: 01/02/2023</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
